import React from "react";
import "../../App.css";

const Repos = () => {
  return (
    <div className="component-container">
      <div className="component-content animate__animated animate__fadeInLeft">
        <p className="content-header">Vínculos laborales</p>
        <p className="content-text">
          <a href="https://www.camarabadajoz.es/">
            Cámara de Comercio de Badajoz
          </a>
          <br />
          <br />
          <a href="https://notariafarres.com/">Notaria Farres</a>
          <br />
          <br />
          <a href="https://camaranavarra.com/">Cámara de Comercio de Navarra</a>
          <br />
          <br />
          <a href="https://www.ccsagradafamilia.net/">
            Centro Cívico Sagrada Familia
          </a>
        </p>
      </div>
    </div>
  );
};

export default Repos;
