import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>+34 66 21 38 427</p>
    </footer>
  );
};
export default Footer;
