import React from "react";
import "../../App.css";
import images from "../../assets/img/images";

const Recomendaciones = () => {
  return (
    <div className="component-container">
      <div className="component-content animate__animated animate__fadeInLeft">
        <p className="content-header">Recomendaciones</p>
        <p className="content-text">
          GPTs que mejora levemente el texto que le envíes, lo corrige y propone
          pequeños cambios, la idea es que le promptees lo que escribiste y
          responda directamente con el texto mejorado, corrige faltas y ordena
          sin cambiar el estilo, es una herramienta rápida para revisar tus
          textos sin modificarlos demasiado. Lo uso permanentemente para
          profesionalizar lo que escribo. Surgió por la necesidad de mejorar
          textos sin tener que promptear y lo optimicé hasta que encontré el
          grado perfecto de cambios.
          <br />
          <br />
          <img
            style={{ maxWidth: "30vh", maxHeight: "auto" }}
            src={images["gpt1"]}
            alt="gpt1"
          ></img>
          <br />
          <br />
          <a href="https://chatgpt.com/g/g-QSq7jPvNh-customizador-de-texto">
            ChatGPT - Customizador de texto chatgpt.com
          </a>
          <br />
          <br />
          Generé un GPTs para resumir papers, la idea es que le mandes el pdf o
          el enlace y te devuelva un pequeño resumen, luego puedes preguntarle
          cosas del documento, puede alucinar así que hay que revisar todo, si
          lo usas después dame feedback
          <br /> <br />
          <img
            style={{ maxWidth: "30vh", maxHeight: "auto" }}
            src={images["gpt3"]}
            alt="gpt3"
          ></img>
          <br /> <br />
          <a href="https://chatgpt.com/g/g-cqM3fZfGQ-revision-de-papers-cientificos">
            ChatGPT - Revisión de papers científicos chatgpt.com
          </a>
          <br /> <br />
          GPTs que da respuestas inteligentes a los problemas amorosos, centrado
          en la comunicación asertiva y el autoconocimiento puede servir como
          fuente de ideas para resolución de conflictos. propone referencias
          bibliográficas y está enfocado en la perspectiva poli.
          <br /> <br />
          <img
            style={{ maxWidth: "30vh", maxHeight: "auto" }}
            src={images["gpt2"]}
            alt="gpt2"
          ></img>
          <br /> <br />
          <a href="https://chatgpt.com/g/g-JnsAE000Y-ayuda-problemas-amor-polidramas-y-dramas">
            ChatGPT - Ayuda problemas amor, polidramas y dramas. chatgpt.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Recomendaciones;
